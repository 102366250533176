<template>
  <div>
    <Modal :options="{width: '40vw'}" @close="$emit('close')">
      <div class="title">{{ !config_nuevo.id ? 'Agregar' : 'Editar' }} configuración</div>
      <div class="body">
        <div class="row form-group">
          <label for="clave" class="col-form-label col-sm-3">Clave</label>
          <div class="col-sm-9"><input v-model="config_nuevo.clave" type="text" name="clave" id="clave" class="form-control"></div>
        </div>
        <div class="row form-group">
          <label for="descripcion" class="col-form-label col-sm-3">Descripciónn</label>
          <div class="col-sm-9"><input v-model="config_nuevo.descripcion" type="text" name="descripcion" id="descripcion" class="form-control"></div>
        </div>
        <div class="row form-group">
          <label for="valor" class="col-form-label col-sm-3">Valor</label>
          <div class="col-sm-9">
            <textarea v-model="config_nuevo.valor" name="valor" id="valor" cols="30" rows="10" class="form-control"></textarea>
          </div>
        </div>
        <div class="row form-group">
          <label for="estatus" class="col-form-label col-sm-3">Estatus</label>
          <div class="col-sm-9">
            <select v-model="config_nuevo.estatus" name="estatus" id="estatus" class="form-control">
              <option value="1">Activo</option>
              <option value="2">Inactivo</option>
            </select>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button class="btn btn-primary mr-2" @click="guardar_config">Guardar</button>
            <button class="btn btn-danger" @click="$emit('close')">Cancelar</button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal'

  import apiConfiguracion from '@/apps/formalizacion/api/v2/configuracion'

  export default {
    components: {
      Modal
    }
    ,props: {
      configuracion: {
        type: Object
        ,default() {
          return {
            clave: null
            ,descripcion: null
            ,valor: null
            ,estatus: 1
          }
        }
      }
    }
    ,data() {
      return {
        config_nuevo: {
          clave: null
          ,descripcion: null
          ,valor: null
          ,estatus: 1
        }
      }
    }
    ,mounted() {
      this.config_nuevo = this.configuracion;
    }
    ,methods: {
      async guardar_config() {
        try {
          if (!this.config_nuevo.clave)
            return this.$helper.showMessage('Error','Debes definir la clave','error','alert');

          if (!this.config_nuevo.descripcion)
            return this.$helper.showMessage('Error','Debes definir la descripción','error','alert');

          if (!this.config_nuevo.valor)
            return this.$helper.showMessage('Error','Debes definir el valor','error','alert');

          let res;

          if (!this.config_nuevo.id)
            res = (await apiConfiguracion.crear_configuracion(this.config_nuevo)).data;
          else
            res = (await apiConfiguracion.editar_configuracion(this.config_nuevo.id, this.config_nuevo)).data;
          this.$log.info('res',res);

          this.$emit('update');
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
    }
  }
</script>

<style lang="scss">
  textarea {
    height: 100px !important;
    resize: none;
  }
</style>